(function () {
  'use strict';

  class ModuleTreeCtrl {
    constructor(Modules, $mdToast, $state, $scope, $rootScope, CloneModuleFlow, Milestones, $mdDialog, Utils, $filter, $window, $timeout, $document, ModuleSet) {
      let vm = this;
      vm.ctrlName = 'ModuleTreeCtrl';
      vm.Modules = Modules;
      vm.$mdToast = $mdToast;
      vm.ModuleSet = ModuleSet;
      vm.$window = $window;
      vm.$timeout = $timeout;
      vm.$document = $document;
      vm.$filter = $filter;
      vm.CloneModuleFlow = CloneModuleFlow;
      vm.Milestones = Milestones;
      vm.$mdDialog = $mdDialog;
      vm.Utils = Utils;
      vm.$state = $state;
      vm.$scope = $scope;
      vm.$rootScope = $rootScope;
      vm.moduleFilterToggle = false;
      vm.search = {
        per_page: 8,
        order_by: 'title',
        order: 'asc'
      };
      /*
      $scope.$on('module:update', function (e, d) {
        vm.$scope.modulesIndex = d;
        vm.init();
      });
      $scope.$on('initIndex', function () {
        vm.init();
      });
      */
      vm.search.query = vm.$rootScope.moduleFilter;
      if (vm.search.query) {
        vm.moduleFilterToggle = true;
      }
      vm.init();
      vm.initSets();
    }
    sortBy() {
      const vm = this;
      if (vm.search.order === 'asc') {
        vm.search.order = 'desc';
      } else {
        vm.search.order = 'asc';
      }
      vm.init();
    }
    init(enter) {
      const vm = this;
      vm.loading = true;
      if (enter === true) {
        vm.search.page = 1;
      }
      vm.Modules.modulesIndex(vm.search)
        .then((data)=>{
          vm.modulesView = data;
          vm.loading = false;
        });
    }
    initSets() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.moduleSetsView = data;
        });
    }
    beforeResource() {
      const vm = this;
      vm.search.page = vm.modulesView.meta.page - 1;
      vm.init();
    }
    nextResource() {
      const vm = this;
      vm.search.page = vm.modulesView.meta.page + 1;
      vm.init();
    }
    broadcast(module, node) {
      let vm = this,
          obj = {group_id: vm.$scope.modulesIndex.id, resource_node_id: node.id, id: module.id};
      vm.Modules.goTo(obj)
        .then((data)=> {
          vm.message(data.message);
        });
    }
    checkTrees(module) {
      let vm = this;
      if (vm.$scope.drag === false) {
        vm.getTree(module);
      }
    }
    getTree(module, show) {
      let vm = this;
      module.showAll = show || !module.showAll;
      if (module.showAll) {
        module.loadingTree = true;
        vm.Modules.moduleView(module)
          .then((singleModule)=> {
            module.loadingTree = false;
            vm.setTree(singleModule, module);
          });
      }
    }
    setTree(singleModule, module) {
      const {module_document: {resource_nodes}} = singleModule;
      if (angular.isArray(resource_nodes)) {
        module.resource_nodes = resource_nodes;
      } else {
        module.resource_nodes = [];
      }
    }
    clone(e, module) {
      let vm = this;
      vm.CloneModuleFlow.show(e, module, (clone)=> {
        vm.Modules.moduleClone(module.id, clone)
          .then(()=> {
            vm.init();
          })
          .catch((response) => {
            const {data: {
              error
            }} = response;
            angular.forEach(error, function (v) {
              vm.message(v.join(' '));
            });
          });
      });
    }
    save(module) {
      const vm = this,
          cloneModule = vm.setupResourceNodes(angular.copy(module));
      module.loadingTree = true;
      vm.Modules.resourceNodesCreate(cloneModule)
        .then(()=> {
          vm.getTree(module, true);
          vm.$scope.$emit('resource:update');
          vm.message('Module updated.');
        });
    }
    moduleFilterToggleHandler() {
      const vm = this;
      vm.moduleFilterToggle = !vm.moduleFilterToggle;
      vm.$timeout(() => {
        vm.$document[0].querySelector('[name="moduleQuery"]').focus();
      }, 100);
    }
    setupResourceNodes(module) {
      angular.forEach(module.resource_nodes, (node, key, resource_nodes) => {
        if (!node.resource) {
          resource_nodes[key] = {
            resource_id: node.id,
            order: key++
          };
        } else {
          resource_nodes[key].order = key++;
        }
      });
      return module;
    }
    goToRoute(state, params = {}, newwindow = false) {
      let vm = this,
          isModule = state === 'module.resource';
      if (isModule || newwindow) {
        vm.$window.open(vm.$state.href(state, params), '_blank');
      } else {
        vm.$state.go(state, params);
      }
    }
    treeOptions() {
      return {
        beforeDrop: function () {
          return true;
        }
      };
    }
    deleteModule(module, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
        .title('Confirm')
        .textContent('Are you sure you want to delete this?')
        .ariaLabel('Confirm')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      vm.$mdDialog.show(confirm).then(function () {
        vm.Modules.moduleDelete({id: module.id})
          .then(()=> {
            vm.message('Module deleted.');
            vm.init();
          });
      });
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    remove(scope) {
      scope.remove();
    }
    edit(node) {
      let vm = this;
      vm.$state.go('dashboard.learning.editResource', {id: node.id});
    }
    openMenu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
    beforeModule() {
      let vm = this;
      if (vm.$rootScope.moduleFilter) {
        vm.search.query = vm.$rootScope.moduleFilter;
      }
      vm.search.page = vm.modulesView.page - 1;
      vm.init();
    }
    nextModule() {
      let vm = this;
      if (vm.$rootScope.moduleFilter) {
        vm.search.query = vm.$rootScope.moduleFilter;
      }
      vm.search.page = vm.modulesView.page + 1;
      vm.init();
    }
  }

  /**
   * @ngdoc object
   * @name learning.index.controller:ModuleTreeCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ModuleTreeCtrl', ModuleTreeCtrl);
}());
